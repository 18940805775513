import $ from "jquery"

activate = () ->
  $(".alert .autoclose-bar").each (i, b) ->
    b = $(b)
    d = b.data("timeout") || 10
    b.width("#{100}%")
    b.css("transition", "all #{d}s linear")
    b.width("#{0}")
    u = ->
      b.closest(".alert").alert("close")#trigger(window.Alert.Event.CLICK_DATA_API)
    setTimeout(u, d * 1000 + 100)

$("html").on "DOMNodeInserted", (i, e) -> 
  activate() if $(e).hasClass('autoclose-bar')

onPageLoad ->
  activate()