import $ from "jquery"
require("./upload_engine")

$ ->
  $('.drop-dialog .drop').hide()
  $('.drop-dialog .no-drop').hide()
  u = $('#fileupload').boxupload()
  dragAnddrop = u.length == 1 && u[0].isXhr()
  if dragAnddrop
    $('.drop-dialog .drop').show()
    $('.drop-dialog .no-drop').hide()
  else
    $('.drop-dialog .no-drop').show()
    $('.drop-dialog .drop').hide()

  $('.zip-build').click (e) ->
    $this = $(this)
    dialog = $($this.data('dialog-selector'))
    return unless dialog
    done = dialog.find('.zip-done')
    waitZip = dialog.find('.wait-zip')
    done.hide()
    $this.blur()
    url = this.href
    openDialog = () ->
      dialog.modal('show')
    openDialogTO = setTimeout openDialog, 1000
    closeDialog = () ->
      dialog.modal('hide')
    millisForPoll = 500
    send = ->
      success = (data) ->
        if data.redirect
          window.location.href = data.redirect
        else if data.result == 'ok'
          done.show()
          waitZip.hide()
          clearTimeout openDialogTO
          setTimeout closeDialog, 2000
          location.href = url
        else
          url = data.url
          setTimeout send, millisForPoll
          if millisForPoll < 2000
            millisForPoll += 500
      xhr = $.get url, success, "json"
      xhr.fail ->
        clearTimeout openDialogTO
        closeDialog()
        alert(dialog.data('zip-failure-message'))
    send()
    return false
  compile_name = (input, cb) ->
    url = input.data('url')
    $.get url, (data) ->
      input.val(data.name)
      cb() if cb

  $('#new_receive_box').click ->
    input = $('#create_box_write_access_form_box_name')
    if input.val() == ''
      compile_name input, ->
        input.select()
  $('#new_create_box_write_access_form').each (i, f) ->
    $(f).find('[type=reset]').click ->
      f.reset()
      compile_name($(f).find('#create_box_write_access_form_box_name'))

  $('.duplicate').click (e) ->
    url = this.href
    $this = $(this)
    dialog = $('#duplicate-wait-dialog')
    redirecting = dialog.find('.redirecting')
    redirecting.hide()
    waitSpin = dialog.find('.wait')
    waitSpin.show()
    show = ->
      dialog.show()
    openTO = setTimeout show, 1000
    success = (res) ->
      clearTimeout openTO
      waitSpin.hide()
      redirecting.show()
      redirecting.find('.target').attr('href', res.url)
      location.href = res.url
    $.post url, success, "json"
    return false
