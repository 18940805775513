import jQuery from "jquery";
import { isSupported, Upload } from "tus-js-client";
require("blueimp-file-upload");

(function ($) {
  var Uploader = function ($data) {
    var box_url = $data.box_url
    var box_upload_url = $data.box_upload_url
    var aborting = false;
    var boxRq;
    var stream_url;
    var form_url, complete_url;
    var waiting = [];
    var xferring;
    var stop = function () {
      if (xferring) {
        var result = confirm($data.stop_confirm);
        if (result) {
          aborting = true;
          xferring.abort();
        }
        return result;
      }
    }

    var dialog = $($data.dialogSelector);
    dialog.find('.stop').click(function () {
      dialog.modal("hide");
    });
    dialog.on('hide.bs.modal', function (e) {
      return stop();
    });
    var bar = dialog.find('#bar .progress-bar');
    var doneFiles = 0;
    var strategy;

    if (isSupported)
      strategy = { create: create_tus, start: start_tus, }
    else
      strategy = {
        create: function (data) { return data; },
        start: function (data, xferring) {
          stream_url = data.data_stream_url;
          form_url = data.data_form_url;
          xferring.submit();
        },
      }

    function start_tus(data, xferring) {
      xferring.options.endpoint = data.data_tus.url;
      xferring.options.headers['Deposito-Path'] = data.data_tus.path;
      xferring.options.headers['Deposito-Auth'] = data.data_tus.auth;//.substring(0,4);
      xferring.start();
    }

    function create_tus(data) {
      var file = data.files[0];
      var upload = new Upload(file, {
        retryDelays: [0, 1000, 3000, 5000],
        metadata: {
          filename: file.name,
          filetype: 'application/octet-stream'
        },
        onError: function (error) {
          console.log("Failed because: " + error)
          alert("Failed: " + error);
          window.location = box_url + '?n=t';
        },
        onProgress: function (bytesUploaded, bytesTotal) {
          var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
          console.log(bytesUploaded, bytesTotal, percentage + "%")
          showProgress(percentage);
        },
        onSuccess: function () {
          console.log("Download %s from %s", upload.file.name, upload.url)
          onComplete(upload.file);
        }
      });
      return upload;
    }

    function queueInfos() {
      if (waiting.length == 0)
        return '&nbsp;';
      else
        return I18n.t('upload_engine.files_in_queue', { count: waiting.length });
    }

    function showStart(file) {
      dialog.find('.current-file').html(file.fileName || file.name);
      dialog.find('.pending').html(queueInfos());
      dialog.modal("show");
      bar.width(0);
    }

    function showProgress(perc) {
      dialog.find('.infos').show();
      dialog.find('.wait-generic').hide();
      bar.width(perc + '%');
    }

    function onComplete(file) {
      $.post(complete_url, {}, function () {
        doneFiles++;
        xferring = undefined;
        dialog.find('.done-files').show();
        var list = dialog.find('.done-files .list');
        list.append('<p><i class="icon-ok text-success"/>' + file.name + "</p>");
        list.scrollTop(list[0].scrollHeight);
        if (waiting.length > 0)
          processFiles();
        else
          window.location = box_url + '?n=t';
      }, "json").fail(function (jqXHR) {
        showError({ jqXHR: jqXHR });
      });
    }

    function isNewBox() {
      return !!boxRq;
    }

    function processFiles() {
      if (box_upload_url) {
        if (waiting.length == 0)
          return;
        if (!xferring) {
          var data = waiting.shift();
          var file = data.files[0];
          xferring = strategy.create(data);
          $.post(box_upload_url, {
            'filename': file.name
          }, function (data) {
            complete_url = data.complete_url;
            showStart(file);
            strategy.start(data, xferring);
          }, "json").fail(function (xhr, text, error) {
            alert("Something unexpected happened: code " + xhr.status + " - " + error);
            window.location = box_url;
          });
        }
      } else {
        if (!boxRq) {
          boxRq = $.post($data.createBoxUrl, {
          }, function (u) {
            box_url = u.url;
            box_upload_url = u.upload_url;
            processFiles(data);
          }, "json").fail(function (xhr, text, error) {
            alert("Something unexpected happened: code " + xhr.status + " - " + error);
            window.location = $data.returnPath;
          });
        }
      }
    }

    function clenupUpload() {
      var mustDelete = isNewBox() && doneFiles == 0;
      if (mustDelete) {
        $.post(box_url, {
          '_method': 'delete'
        }, function () {
          window.location = $data.returnPath;
        }, "json");
      }
      else
        window.location = box_url;
    }

    function showError(data) {
      if (data.jqXHR) {
        var xhr = data.jqXHR, message;
        try {
          message = JSON.parse(xhr.responseText).message;
        } catch (err) {
          message = xhr.responseText;
        }
        alert("Unexpected. Status: " + xhr.status + ". Message: " + message);
      }
      clenupUpload();
    }

    this.add = function (data) {
      waiting.push(data);
      processFiles();
    }
    this.fail = function (data) {
      if (aborting)
        clenupUpload();
      else
        showError(data);
    }
    this.onComplete = onComplete
    this.showProgress = showProgress
    this.stream_url = function (val) { if (val) stream_url = val; return stream_url; }
  };

  $.fn.boxupload = function () {
    return this.map(function () {
      const $e = $(this)
      const uploader = new Uploader($e.data());

      function isIframe(data) {
        return data.dataType.indexOf('iframe') > -1;
      }

      $e.fileupload({
        dataType: 'json',
        multipart: false,
        maxChunkSize: 1e6,
        add: function (e, data) {
          uploader.add(data)
        },
        formData: {
          [$('meta[name="csrf-param"]').attr('content')]: $('meta[name="csrf-token"]').attr('content') // old IE
        },
        progress: function (e, data) {
          var file = data.files[0];
          var perc = data.loaded / data.total * 100;
          uploader.showProgress(perc);
        },
        drop: function (e, data) {
          //if(data.files.length > 1) {
          //alert("#{escape_javascript t('.only_one_file')}");
          //return false;
          //}
        },
        send: function (e, data) {
          var file = data.files[0];
          data.formData['filename'] = file.fileName || file.name;
          //if ( data.contentType != false ) //Safari does multipart and sets this
          if (isIframe(data))
            data.url = form_url;
          else
            data.url = uploader.stream_url();
          data.contentType = "application/octet-stream";
          data.accept = 'application/json';
        },
        fail: function (e, data) {
          uploader.fail(data);
        },
        chunksend: function (e, data) {
          data.url = uploader.stream_url();
          data.contentType = "application/octet-stream";
        },
        chunkdone: function (e, data) {
          if (data.result.url) {
            uploader.stream_url(data.result.url);
          }
        },
        done: function (e, data) {
          uploader.onComplete(data.files[0]);
        },
      });

      return {
        isXhr: function () {
          var fu = $e.data('blueimpFileupload');
          return fu._isXHRUpload(fu.options);
        }
      };
    });
  }

  function isCarriyingFiles(e) {
    var dt = e.originalEvent && e.originalEvent.dataTransfer;
    return dt && dt.types && dt.types.indexOf("Files") >= 0;
  }
  $(document).bind('dragover', function (e) {
    if (!isCarriyingFiles(e))
      return;
    var dropZone = $('#drop-here-glass'),
      timeout = window.dropZoneTimeout;
    if (timeout) {
      clearTimeout(timeout);
    } else {
      dropZone.show();
    }
    var hoveredDropZone = $(e.target).closest(dropZone);
    window.dropZoneTimeout = setTimeout(function () {
      window.dropZoneTimeout = null;
      dropZone.hide();
    }, 100);
  });
})(jQuery);
