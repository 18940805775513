import $ from "jquery"

put_toggle = (toggle) ->
  it = this
  c = this.checked
  $.post $(this).data('url'),
    _method: 'PUT',
    value: c,
    ->
      it.checked = c
  .fail (r) ->
    alert("Error: " + r.statusText)
    toggle()

$ ->
  $('.toggle-http-put').on 'change', ->
    it = this
    put_toggle.call this, ->
      it.checked = !it.checked

  $('.toggler').click ->
    b = $(this)
    item = $('#' + b.data('toggle-id'))
    item.toggle()
    focus = b.data('focus-id')
    if item.is(":visible") && item.focus
      $('#' + focus).focus()
      if b.data('select-all')
        $('#' + focus)[0].select()


  $('.focus-on-load').focus()

  $('.auto-select').click ->
    this.focus()
    this.select()

  it = $('.first-focus')
  it.focus()
  it.select()

  $('.modal').on 'shown.bs.modal', ->
    it = $(this).find('.first-focus')
    it.focus()
    it.select()

  $('.modal.start-visible').modal("show")

  $('.switch-toggle-http-put').click ->
    it = this
    put_toggle.call this, ->
      $(it).prop("checked", !$(it).prop("checked"))
