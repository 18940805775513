import { Tooltip } from "bootstrap"
import $ from "jquery"

$(function () {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('.tipped'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl)
  })
})

