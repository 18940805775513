import $ from "jquery"

$ ->
  $('.autocomplete_mail').each (n, el) ->
    src = (q, cbs, cbas) ->
      $.get $(el).data('url'), { term: q }, cbas
    $(el).typeahead {
    }, {
      name: 'src',
      source: src,
      display: (data) ->
        data.label
    }
    prefix = $(el).data('prefix')
    $(el).bind 'typeahead:select', (event, data) ->
      f = ->
        $("##{prefix}_message").focus()
        $("##{prefix}_name").val(data.name)
        $("##{prefix}_mail_address").val(data.email)
        $("##{prefix}_save_contact").prop('checked', false)
      #setTimeout f, 1000
      f()
