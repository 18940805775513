import $ from "jquery"
import ClipboardJS from "clipboard"

$ ->
  cl = new ClipboardJS('.copy-to-clip')
  cl.on "success", (event) ->
    el = $(event.trigger)
    original = el.data('bs-original-title')
    el.attr('title', el.data('copied-text'))
    el.tooltip('_fixTitle')
    el.tooltip('show')
    el.attr('title', original)
  $('.copy-to-clip').on 'mouseenter', ->
    $(this).tooltip('_fixTitle')
    $(this).tooltip('show')
  $('.copy-to-clip').on 'mouseleave', ->
    $(this).tooltip('hide')
