$ = jQuery

isTypeaheadGenerated = (el) ->
  $(el).hasClass('tt-hint') || $(el).hasClass('tt-input')

activate = () ->
  $("[data-typeahead]").each (i, e) ->
    return if isTypeaheadGenerated(e)
    d = $(e).data('typeahead')
    url = d.source
    target = $(this).closest('.fields').closestChild(d['target-selector'])
    if ( target.length == 0 )
      throw new Error("Cannot find a target to update")
    queryPar = 'term'
    displayProp = 'label'
    source = (query, syncResult, asyncResult) ->
      $.getJSON(url, {"#{queryPar}": query}).done(asyncResult)

    typ = $(e).typeahead {
    }, {
      source: source,
      display: (s) ->
        s[displayProp]
    }
    typ.bind 'typeahead:select', (ev, suggestion) ->
      $(target).val(suggestion.id)

onPageLoad ->
  activate()

$("html").on "DOMNodeInserted", activate