# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/

import $ from "jquery"
import {Modal} from "bootstrap"

digits_code = (validChar, transformChar, digits_selector, field_selector, form_selector) ->
  digits = $(digits_selector)
  field = $(field_selector)
  form = $(form_selector)
  trySubmit = ->
    return unless field.val().length == digits.length
    waitDlg = $('#code-wait-dialog')[0]
    waitDlg.addEventListener 'shown.bs.modal', ->
      submit = ->
        form.submit()
      setTimeout submit, 0
    new Modal(waitDlg).show()

  parse = ->
    parts = digits.map (i, d) ->
      char = d.value[0] || ""
      char = char.toUpperCase()
    field.val(parts.get().join(""))
    trySubmit()

  skips = digits[0..-2]
  digits.keydown (e) ->
    k = transformChar(e.originalEvent.key)
    if validChar(k)
      this.value = k
      parse()
      return false
    code = e.keyCode || e.which
    if code == 8
      return true
    if code == 9
      return true
    return false
  skips.map (i, f) ->
    $(f).keydown (e) ->
      return unless validChar(e.originalEvent.key.toUpperCase())
      digits[i+1].focus()
      digits[i+1].select()
  onPageLoad ->
    $(digits[0]).focus()

######################################

validChar = (c) ->
  '0123456789'.indexOf(c) > -1
transformChar = (c) ->
  return c.toUpperCase()

$ ->
  digits_code(
    validChar,
    transformChar,
    ".secure-box-code-digit",
    'input#secure_box_authorization_code',
    'form#new_secure_box_authorization:last')
