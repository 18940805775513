import "bootstrap"
import I18n from 'i18n-js/index.js.erb'
import "../application/bootstrap_activation"
import "../stylesheets/application"

window.jQuery = require("jquery") // if not here Rails ujs does not integrate with jQuery
require('@rails/ujs').start();
//require('turbolinks').start()

require("typeahead.js")
require("jquery_nested_form")

require("../application/00001.coffee")
require("../application/common")
require("../application/secure_box_authorizations")
require("../application/boxes")
require("../application/my_typeahead")
require("../application/clipboard")
require("../application/alert_auto_close")
require("../application/address")

window.I18n = I18n
